import Head from "next/head";
import React, {ReactElement} from "react";
import {PageMeta as PageMetaFragment} from "./__generated__/PageMeta";
import {CampaignPageFragment_languageVariants as languageVariantsFragment} from "@cmsPages/CampaignPage/__generated__/CampaignPageFragment";
import {useTheme} from "styled-components";
import {ComponentProps} from "@components/ComponentProps";
import {JsonLd} from "react-schemaorg";
import {BreadcrumbList} from "schema-dts";

export type PageMetaProps = ComponentProps<
  PageMetaFragment
> & {
  languageVariants?: (languageVariantsFragment | null)[] | null;
};

const PageMeta = ({
  path,
  metatags,
  breadcrumbs,
  languageVariants
}: PageMetaProps): ReactElement => {
  const theme = useTheme();
  return (
    <>
      <Head>
        <meta charSet="utf-8"/>
        <meta content="text/html; charset=UTF-8" name="Content-Type" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <title>{metatags?.metaTitle}</title>
        {metatags?.metaDescription && (
          <meta name="description" content={metatags.metaDescription} />
        )}
        {metatags?.metaRobots && (
          <meta name="robots" content={metatags?.metaRobots} />
        )}

        {/* Facebook tags */}
        <meta property="og:title" content={metatags?.metaTitle} />
        <meta property="og:description" content={metatags?.metaDescription || ""} />
        <meta property="og:url" content={path} />
        {metatags?.metaImage?.md && (
          <meta property="og:image" content={metatags?.metaImage.md} />
        )}

        {/* Twitter tags */}
        <meta name="twitter:card" content="summary_large_image"/>
        <meta property="twitter:title" content={metatags?.metaTitle} />
        <meta property="twitter:description" content={metatags?.metaDescription || ""} />
        {metatags?.metaImage?.md && (
          <meta property="twitter:image" content={metatags?.metaImage.md} />
        )}

        {/* Canonical link */}
        <link rel="canonical" href={metatags?.canonical || path} />

        {/* Favicon/branding tags */}
        <link rel="icon" href={`/${theme.key}/favicon.ico`} />
        <link rel="manifest" href={`/${theme.key}/site.webmanifest`} />
        <link rel="apple-touch-icon" sizes="180x180" href={`/${theme.key}/apple-touch-icon.png`} />
        <link rel="icon" type="image/png" sizes="32x32" href={`/${theme.key}/favicon-32x32.png`} />
        <link rel="icon" type="image/png" sizes="16x16" href={`/${theme.key}/favicon-16x16.png`} />
        <link rel="mask-icon" href={`/${theme.key}/safari-pinned-tab.svg`} color={theme.colors.background} />

        {languageVariants &&  
          languageVariants.map((languageVariant: languageVariantsFragment | null) => {
            if (languageVariant) {
              return <link rel="alternate" key={`${languageVariant.language}`} href={`${languageVariant.url}`} hrefLang={`${languageVariant.language}`} />
            }
          })
        }

        <meta name="msapplication-TileColor" content={theme.colors.background} />
        <meta name="msapplication-config" content={`/${theme.key}/browserconfig.xml`} />
        <meta name="msapplication-square70x70logo" content={`/${theme.key}/mstile-70x70.png`} />
        <meta name="msapplication-square150x150logo" content={`/${theme.key}/mstile-150x150.png`} />
        <meta name="msapplication-wide310x150logo" content={`/${theme.key}/mstile-310x150.png`} />
        <meta name="msapplication-square310x310logo" content={`/${theme.key}/mstile-310x310.png`} />
        <meta name="theme-color" content={theme.colors.background} />

        {breadcrumbs?.length &&
          <JsonLd<BreadcrumbList>
            item={{
              "@context": "https://schema.org",
              "@type": "BreadcrumbList",
              "itemListElement": breadcrumbs.map((el, idx) => ({
                "@type": "ListItem",
                "position": (idx + 1),
                "name": el.title,
                "item": el.url,
              })),
            }}
          />
          }
      </Head>
    </>
  );
};

export default PageMeta;
