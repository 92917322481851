import {useEffect, useState} from 'react';
import {WindowWidth} from './WindowWidth';

export function useTablet(minWidth = WindowWidth.Tablet, boolean = true): boolean {
  const [tablet, setTablet] = useState(boolean);

  useEffect(() => {
    const media = window.matchMedia(`(min-width: ${minWidth}px)`);
    setTablet(!media.matches);
    const handler = (e: MediaQueryListEvent): void => setTablet(!e.matches);
    media.addListener(handler);
    return () => {
      media.removeListener(handler);
    };
  }, [minWidth]);

  return tablet;
}
