import {buttonPrimary, buttonSecondary} from '@/theme/lib';
import styled from 'styled-components';

export interface StyledButtonProps {
  primary?: boolean;
};

const StyledButton = styled.a<StyledButtonProps>`
  ${(props) => props.primary ? buttonPrimary : buttonSecondary}
  &[target='_blank']::after {
    content: unset;
    display: none;
  }
`;
export default StyledButton;
