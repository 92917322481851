import {useTheme} from 'styled-components';
import ErrorPageContent from '@/components/ErrorPageContent';

export default function Error404() {
  const { key } = useTheme();

  let errorProps = {
    errorNumber: 404,
    title: '',
    subhead: '',
    body: ''
  };

  const zslErrorProps = {
    errorNumber: 404,
    title: 'This page is extinct',
    subhead: 'Error 404 page not found',
    body: 'We’re sorry, this page is no longer with us. Unlike this page, we’re all living conservationists working to bring species back from the brink of extinction.\r\n\r\nTry our useful links below to get you back on track, or donate what you can to help keep iconic species a part of our future.',
  };

  const otherErrorProps = {
    errorNumber: 404,
    title: 'Error 404. Page not found',
    subhead: '',
    body: 'We’re sorry, this page is no longer with us. Unlike this page, we’re all living conservationists working to bring species back from the brink of extinction.\r\n\r\nTry our useful links below to get you back on track, or donate what you can to help keep iconic species a part of our future.',
  };

  switch (key) {
    case 'london':
    case 'whipsnade':
      errorProps = otherErrorProps;
      break;
    default:
      errorProps = zslErrorProps;
      break;
  }

  return (
    <main>
      <ErrorPageContent {...errorProps} />
    </main>
  );
}
